.headerText{
    font-size: 34px;
    text-align: center;
}
.inputMain {
    width: 600px;
    padding-left: 50px;
    padding-right: 50px;
    margin: auto;
    
}
.inputText{
    border: 1px solid #DEDCD7;
    height: 40px;
    padding: 3px;
    width: 100%;
}
.btn{
   background-color: #FFB902; 
   color: black;
    padding: 5px;
    width: 210px;
    height: 60px;
}