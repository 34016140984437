@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;700;900&display=swap');

body{
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  color: #110F36;
  background-color: #E5958E;
}

.fontSub{
  font-size: 14px;
}

.mainPC{
  width: 100%;
  max-width: 1400px;
  margin: auto;
}