
.mainLeftMenu{
    width: 270px;
    height: 100vh;
    background-color: white;
}
.brx{
    border: 1px red solid
}
.menuSelect {
    background-color: #E5958E;
    color: white;
}