.rpanel{
    height: 100vh;
    overflow-y: auto;
}
.trickText{
    font-size: 30px;
    border: 2px #E5958E dashed;
    width: 400px;
    margin:auto;
    
}
.reviewText{
    font-size: 28px;
    font-weight: bold;

}
.priceOrange{
    color: #FFB902;
    font-weight: bold;
}
.circle {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    padding: 10px;
    background: #FFB902;
 
    color: #FFF;
    text-align: center;
    font: 16px Arial, sans-serif;
  }