.inputText{
    width: 100%;
    max-width: 400px;
    height: 45px;
    border-radius: 3px;
}
.textAreaShow{
    width: 100%;
    max-width: 400px;
    border-radius: 3px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    resize: none;
    background-color: #f8f8f8;
    height: 350px;
}
.btn{
    height: 40px;
    width: 150px;
    border-radius: 5px;
}